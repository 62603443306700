@import "../../../styles/variables";

.section {
    padding-top: $section-vertical-padding;
    padding-bottom: $section-vertical-padding;
    display: flex;
    flex-direction: column;

    > .container > *:first-child {
        margin-top: 0px;
    }
}