@import "../../../styles/variables";

$modal-header-height: 30px;

.modal {
  &__box {
    position: absolute;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    width: 90%;
    height: 84%;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
    overflow: unset;
    z-index: 4;

    .modal__header {
      width: 100%;
      padding: 0px;
      height: $modal-header-height;

      .modal__close {
        display: block;
        float: right;
        margin-left: auto;
        color: $white;
        text-align: right;
        padding: 6px;
        cursor: pointer;
      }
    }

    .modal__body {
      padding: 20px;
      background: #fff;
      border-radius: $border-radius-small;
      width: 100%;
      height: calc(100% - #{$modal-header-height});
      overflow: auto;

      > *:first-child {
        margin-top: 0px;
      }
    
      > *:last-child {
        margin-bottom: 0px;
      }
      
      &-iframe {
        padding: 0px;
        overflow: unset;
        overflow: hidden;
        border: none;
      }

      .modal__iframe {
        width: 100%;
        height: 100%;
        overflow: unset;
      }
      
      &-disablepadding {
        padding: 0px;
      }

      .card {
        min-height: auto;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
  }
}

.ReactModal__Overlay--after-open{
  transition: 0.2s;
  opacity: 1;
  
  .modal {
    &__box {
      width: 90%;
      height: 90%;
    }
  }
}

.ReactModal__Overlay--before-close{
  transition: 0.2s;
  opacity: 0;
  
  .modal {
    &__box {
      width: 100px;
      height: 50px;
      overflow: hidden;
    }
  }
}