@import "../../../styles/variables";

.dateinput {
  @include input-wrap;

  &__input {
    @include input;
  }
}

.react-datepicker {
  display: flex;
  flex-direction: column;

  &__day--keyboard-selected {
    background: $purple-primary;

    &:hover {
      background: $purple-secondary;
    }
  }

  &__header {
    background-color: $purple-primary;
  }

  &__current-month {
    color: $white;
  }

  &__day-name {
    color: $white;
  }

  .button {
    margin: 0px 20px 20px 20px;
  }

  &-popper,
  &__triangle {
    transition: 0s;
  }

  &__month-select {
    margin-top: $margin-top-small;
    background-color: white;
    border: $border-width-thin;
    border-radius: $border-radius-small;
  }

  &__year-select {
    background-color: white;
    border: $border-width-thin;
    border-radius: $border-radius-small;
  }
}
