@import "../../styles/variables";
* { text-shadow: none; }
.hide { display: none; }
.form-group {
  margin-bottom: 20px;
  label { font-weight: bold;color:#222 }
  .form-control { display: block; width: 100%;padding: 10px;border-radius: 5px;border:1px solid #a6a6a6; }
}
.box-wrap {
  width: 230px; max-width: 100%; margin-left: 20px; text-align: center;float: left;
  .header-title { font-size: 24px;line-height: 30px;margin: 0 0 20px 0; font-weight: normal;}
  .box {
    border-radius: 10px; padding: 40px; background: #64b3eb;margin-bottom: 20px;
    * { color: #fff }
    h3 { font-size: 2rem;line-height: 2rem;margin: 0;font-weight: 100; }
    label {}
    &.box-dark { background: #51429a; }
  }
}
.dashboard {
  a { color: #64b3eb }
  background: #fff;
  p, p * { font-size: 20px; color: #505f98; line-height: 30px;}
  .container {
    padding: 20px 0;
    column-gap: 10px;
    position: relative;
    .header-title-2 {
      margin: 0 auto 10px;
      border-bottom: 1px solid;
      padding-bottom: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }
    &.with-sidebar {
      /*display: grid;
      grid-template-columns: 1fr 3fr !important;*/
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      flex-wrap: nowrap;
      .left-sidebar, .content {
        padding: 15px;
        border-radius: 5px;
      }
      .left-sidebar {
        border: 1px solid #505F98;
        border-radius: 5px;
        min-height: 500px;
        width: 280px;
      }
      .content {
        width: 800px;
        max-width: 100%;
        overflow: hidden;
      }
      /*&.reports {
        align-items: normal;
        > .left-sidebar {
          width: 300px;
          * {
            font-size: 16px;
            color: #505F98;
          }
          label {
            font-weight: 500;
            font-size: 20px;
          }
        }
        ul.scroller {
          li { margin-bottom: 5px; }
          .checkbox { margin-bottom: -5px; }
        }
      }*/
    }
  }
  &.isloading .content {
    position: relative;
    &:before {
      display: block;
      content: "Loading...";
      width: 100%;
      height: 100%;
      position: absolute;
      background: #ffffffdb;
      z-index: 2;
      text-align: center;
      font-size: 2rem;
      vertical-align: middle;
      color: transparent; //#0067b0;
      line-height: 50vh;
      letter-spacing: 3px;
      text-transform: uppercase;
      left: 0;
      top: 0;
      z-index: 2;
      background: url("https://vetxtend.com.au/wp-content/uploads/2024/12/loading.webp") no-repeat;
      background-position: center center;
      background-color: #fff;
      background-size: 250px;
      opacity: 0.5;
    }
  }
  .checkbox {
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
    border: 1px solid #505F98;
    cursor: pointer;
    border-radius:3px;
    display: inline-block;
    &.active {
      color: #fff;
      position: relative;
      background: #505F98;
      overflow: hidden;
      &:before {
        position: absolute;
        content: " ";
        left: 0px;
        right: -1;
        margin: auto;
        top: 1px;
        width: 40px;
        height: 40px;
        background: url("https://thrivingpets.com.au/wp-content/themes/thrivingpets/css/images/icon-check.png") 0 0 no-repeat;
      }
    }
  }
}
.section-row {
  padding: 20px 0;
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    &.wellness-insights {
      align-items: flex-start;
      flex-wrap: nowrap;
      .members-insights {
        width: 700px;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        .box-layout {
          border: 1px solid;
          padding: 15px;
          border-radius: 5px;
          text-align: center;
          color: #505f98;
          width: 180px;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          h4 {
            line-height: 40px;
            font-size: 24px;
            font-weight: 500;
            margin: 0;
            color: #505f98;
          }
        }
      }
    }
  }
  &.icon-tabs{
    background: #f3fafc;
    .inner-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: #505f98;
      font-size: 20px;
      cursor: pointer;
      .icon-box {
        width: 120px;
        height: 120px;
        max-width: 100%;
        margin-bottom: 20px;
        border-radius: 100%;
        padding: 5px;
        img {
          max-width: 100%;
        }
      }
      a {
        color: $purple-primary;
        font-size: 24px;
        font-weight: 500;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
}
ul.list-block {
  margin: 0; padding: 0; list-style: none;
  &.isloading { opacity: 0.5 }
  > li {
    padding: 10px;
    border: 1px solid #98abb5;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    row-gap: 10px;
    min-height: 100px;
    a { margin: 0;}
    .desc-wrapper {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }
    > * {
      color: #505F98;
      text-decoration: none;
      .heading {
        font-size: 22px;
        display: block;
      }
      .location {
        font-size: 14px;
      }
    }
    .edit {
      width: 40px;
      cursor: pointer;
    }
    &.active {
      * { color: #fff }
      background: #505F98;
      border-color: #505F98;
      a { color: #fff }
      .btn.switch {
        background: #fff;
        color: #505F98;
      }
    }
  }
}
.subscription-section {
  padding: 10px;
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  border-radius: 3px;
  width: 100%;
  align-items: center;
  > span { }
}
.grid-row { display: grid; }
.table {
  * {
    color: #505F98;
    font-size: 18px;
  }
  border-collapse: collapse;
  width: 100%;
  td, th {
    padding: 10px 5px;
    height: 60px;
  }
  &.table-bordered {
    tr:nth-child(2n) {
      background: #64b3eb24;
      border-top: 1px solid #4499d5;
      border-bottom: 1px solid #4499d5;
    }
  }
}
.section { padding: 30px auto;}
.scroller {
  overflow-y: scroll;
  background: #fdfdfd;
  &.max-220 {
    max-height: 238px;
    border-bottom: 1px solid #505f98;
  }
  &.max-500 {
    max-height: 528px;
    border-left: 1px solid #505f98;
    border-bottom: 5px solid #505f98;
    border-top: 5px solid #505f98;
  }
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    background: #d7d7d7;
  }
  &::-webkit-scrollbar-thumb {
    background: #51429a;
    min-height: 100px;
  }
}
.canvasjs-chart-credit { display: none; }
.details-display {
  width: 400px;
  max-width: 100%;
  position: relative;
  padding-right: 20px;
  &:after {
    content: " ";
    display: block;
    width: 1px;
    height: 80%;
    position: absolute;
    right: 0px;
    top: 0;
    border-right: 1px solid #afc4d2;
  }
  p, p * { font-size: 20px; }
  span.span-title {
    border-bottom: 1px solid #d8e6ef;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #3f8bc1;
    padding-bottom: 5px;
  }
}
.pets-table {
  a { color: #505f98 }
}
.btn-wrapper.download-agreement {
  background: #78b7e4;
  color: #fff;
  width: 315px;
  max-width: 100%;
  border-radius: 24px;
  padding: 5px 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
  margin: auto;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: none;
  cursor: pointer;
}
#report-rebate-monthly{
  display: block;
  padding: 20px;
  .logo {
    display: block;
    margin: auto;
  }
  h2 {
    font-weight: normal;
    text-align: center;
    color: #505F98
  }
  table {
    border: 1px solid #222;
    border-collapse: collapse;
    thead {
      background: #505F98;
      * { color: #fff;}
    }
    th, td {
      border: 1px solid #222;
      padding: 5px;
      font-size: 16px;
      font-weight: normal;
    }
    td {
      height: 70px;
    }
  }
}
.upload-prescription {
  position: relative;
  .label {
    font-weight: bold;
    text-shadow: none;
    color: #505f98;
    margin-bottom: 7px;
    display: block;
  }
  input[type="file"] {
    position: relative;
    z-index: 2;
    height: 45px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #3f8bc1;
    border-radius: 5px;
    margin-left: 0;
    margin-bottom: 20px;
    color: #3f8bc1;
  }
  input:before {
    position: absolute;
    background: #3f8bc1;
    width: 122px;
    padding: 10px 0;
    color: #fff;
    z-index: 0;
    text-align: center;
    content: "Choose File";
  }
}
@media only screen and (max-width: 939px) {
}

@media only screen and (max-width: 676px) {
  ul.list-block {
    > li {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .section-row {
    .container {
      &.wellness-insights {
        flex-wrap: wrap;
        .members-insights {
          .box-layout {
            width: 30%;
          }
        }
      }
    }
  }
  .details-display {
    width: 100%;
    &:after {
      display: none;
    }
  }
  .dashboard {
    .container.with-sidebar {
      .left-sidebar {
        * { font-size: 16px;}
      }
    }
  }
}

