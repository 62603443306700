@import "../../styles/variables";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }

.verifyButtons {
  display: flex;
  flex-direction: column;
  button {
    align-self: end;
    margin-right: 0px;
    width: 200px;
  }
  a {
    align-self: end;
    padding-top: $margin-top-medium;
  }
}
.pharmacy-text {
  margin: 20px 0 0;
  padding: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background: #f2fafa;
  h4 { font-size: 22px; margin: 0; color: #505F98 }
  p {
    color: #505F98 !important;
    line-height: 24px;
    font-size: 18px; }
}
.next-steps {
  * { color: #505F98; }
  h2 {}
  h4 {
    font-size: 22px;
    font-weight: normal;
    line-height: 30px;
    &.indent {
      padding-left: 40px;
    }
  }
  .round-number {
    background: #3f8bc1;
    color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    margin-left: -50px;
    margin-right: 10px;
    font-weight: bold;
    position: absolute;
  }
}

.none { display: none !important;}
.flex { display: flex; column-gap: 15px; }
.block { display: block !important; }

/*.padding-20 { padding: 20px; }

.margin-0 { margin:0 }
.mt-20 { margin-top:20px }
.mt-30 { margin-top:30px }
.mt-40 { margin-top:40px }

.justify-left { justify-content: flex-start; }
.justify-center { justify-content: space-between; }

.align-left { align-items: flex-start; }
.align-center { align-items: center; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-capitalize { text-transform: capitalize; }
.text-uppercase { text-transform: uppercase; }
.text-lowercase { text-transform: lowercase; }

.text-dark { color: #64B3EB }
.text-light { color: #fff !important; }

.mb-0 { margin-bottom: 0 !important; }

.bg-primary { background: #64B3EB; color: #fff; }
.bg-secondary { background: #505F98; color: #fff; }
*/

a { cursor: pointer; }
/*HEADER TITLE*/
h2 { font-size: 34px; margin:0; margin-bottom: 20px; line-height: 45px; }
h3 { font-size: 24px; }
.title-1, .title-2 {
  span { font-size: 18px; line-height: 18px; font-weight: 100; display: block; color:#8e80bc; margin-top: 10px; }
}
.title-2 { color:#505F98;margin-bottom: 0; font-family: "Atten New Extrabold";}
.big { font-size: 70px; font-weight: 500; line-height: 90px; }

/*BUTTON*/
.btn {
  background: transparent;
  padding: 10px 20px;
  border-radius: 30px;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 18px;
  text-decoration: none;
  font-weight: 500;
  min-width: 200px;
  max-width: 100%;
  margin: auto;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  &.btn-white { background: #fff; color: #505F98; }
  &.btn-grey { background: #eeeeee; color: #505F98; }
  &.btn-blue { 
    background: #78b7e4; color: #fff;
    span { color: #fff; }
  }
  &.btn-dark-blue { 
    background: #3f8bc1; color: #fff;
    span { color: #fff; }
  }
  &.btn-purple { 
    background: #51429a; color: #fff;
    span { color: #fff; }
  }
  &.btn-purple-online { 
    background: transparent; color: #51429a; text-decoration: underline; border: 1px solid;
    span { color: #51429a; }
  }
  &.btn-blue { 
    background: #64b3eb; color: #fff;
    span { color: #fff; }
  }
  &.btn-green { 
    background: #86c6cc; color: #fff;
    span { color: #fff; }
  }
  &.btn-lg { padding: 20px; border-radius: 50px; }
  &.square { border-radius: 8px; }
  &.bordered { border: 1px solid; }
  &.bordered-purple { border: 1px solid #505F98; color: #505F98 }
  &.bordered-green { border: 1px solid #86c6cc; color: #86c6cc }

  &.btn-small-font { font-size: 12px; column-gap:5px; padding:5px; font-weight: bold; }
  &.disabled { cursor: progress; opacity: 0.7; }
  &.show-filter { border-radius: 8px; display: none; }
}

.modal__box { 
  .modal__body-iframe {
    border-radius: 15px;
    font-family: 'Atten New';
  }
}
.icon-span {
  display: inline-block;
  &.lg {
    min-width: 60px;
    min-height: 60px;
    border-radius: 40px;
    font-size: 40px;
    line-height: 60px;
  }
}
.bottom-action {
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: 20px auto; 
  * {
    cursor: pointer;
  }
  img { width: 20px;}
  &.payment {
    column-gap: 10px;
    width: 560px;
    >button {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
.progress-bar {
  height: 5px;
  background: transparent;
  margin-top: -3px;
  display: flex;
  .progress {
    height: 5px;
    display: block;
    background: #505F98;
    width: 100%;
    position: relative;
    &:after {
      content: " ";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: #fff;
      border-radius: 10px;
      right: 0px;
      position: absolute;
      top: -3px;
      border: 2px solid #505F98;
    }
    &.active {
      &:after {
        height: 20px;
        width: 20px;
        background: #505F98;
        top: -8px;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
.react-datepicker {
  * { font-family: verdana;}
  border: 1px solid #4b3987;
  .react-datepicker__triangle {
    color:red;
    &:after {
      border-bottom-color: #4b3987 !important;
    }
  }
  .react-datepicker__month-select, .react-datepicker__year-select{
    padding: 3px 10px;
    font-size: 15px;
    text-transform: uppercase;
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
  .react-datepicker__day {
    &:hover {
      background: #b2a6d9;
      border-radius: 2px;
      color: #fff;
    }
    &--keyboard-selected {
      border-radius: 2px;
    }
  }
}
.Toastify {
  .Toastify__toast-container--bottom-center {
    max-width: 100%;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: #00000040;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Toastify__toast-theme--light {
    &.Toastify__toast--error {
      background: var(--toastify-color-light);
      font-size: 18px;
      color: #505F98;
      width: 500px;
      max-width: 100%;
      margin: 5px auto;
    }
  }
}
.input-group {
  input {
    padding: 10px;
    font-size: 18px;
    color: #8ec6cd;
    outline: none;
    &.input-text {
      border: 1px solid #8ec6cd;
      border-radius: 5px 0 0 5px;
    }
    &.input-btn {
      background: #8ec6cd;
      border: 1px solid #8ec6cd;
      color:#fff;
      border-radius: 0 5px 5px 0;
    }
  }
}

.download-qrcode {
  border:none !important;
  .wrapper {
    width: 800px;
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    align-items: center;
    justify-content: center;
    .download-bottons {
      .title {
        font-size: 24px;
        margin: 20px 0;
        font-weight: bold;
        text-align: center;
      }
      .btn-wrapper{
        cursor: pointer;
        background: #78b7e4;
        padding: 8px;
        width: 350px;
        display: block;
        max-width: 100%;
        border-radius: 32px;
        text-transform: uppercase;
        color: #fff;
        height: 46px;
        line-height: 30px;
        font-weight: 500;
        font-size: 20px;
        margin: 0px auto 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.link {
          text-transform: lowercase;
          background: #fff;
          color: #222;
          height: 52px;
          text-overflow: ellipsis;
          border: 2px solid #78b7e4;
          padding-top: 9px;
        }
      }
      .btn-wrapper .img-wrap{
        background: #3f8bc1;
        border-radius: 32px;
        float: left;
        margin-right: 15px;
        padding: 5px 8px 8px 6px;
        margin-top: -4px;
        margin-left: -3px;
        height: 38px;
        display: block;
        width: 38px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    #qr-code {
      svg { max-width: 100%; }
    }
  }
  &.highlight #qr-code {
    position: relative;
    &:after {
      display: block;
      width: 60px;
      height: 60px;
      content: "";
      background: url(https://vetxtend.com.au/wp-content/uploads/2024/02/icon-hand3-300x238.png) no-repeat 4px 10px;
      background-size: 80%;
      position: absolute;
      top: 46%;
      border-radius: 100%;
      right: -28%;
      background-color: #a2d0e2;
      border: 5px solid #fff;
    }
  }
  &.highlight .download-bottons {
    position: relative;
    &:after {
      display: block;
      width: 100px;
      height: 100px;
      content: "";
      background: url(https://vetxtend.com.au/wp-content/uploads/2024/02/icon-hand3-300x238.png) no-repeat 9px 16px;
      background-size: 80%;
      position: absolute;
      top: 25%;
      border-radius: 100%;
      right: -30%;
    }
  }
}
.image-preview {
  border-radius: 100%;
  height: 200px;
  width: 200px;
  max-width: 200px;
  border: 3px solid #7dc6cb;
  background: url(https://vetxtend.com.au/wp-content/uploads/2024/06/no-image-placeholder.jpg);
  background-size: contain;
  background-position: center;
}
.image-preview.logo-upload{
  background: url(https://vetxtend.com.au/wp-content/uploads/2024/10/logo-image-placeholder.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.image-uploader {
  margin-top: 20px;
  .inputlabel__label {
    font-weight: bold;
    color: #505f98;
  }
  .fileinput__input {
    padding: 0;
    position: relative;
    display: inline-block;
    width: 300px;
    max-width: 100%;
    height: 50px;
    background: #f9f7ff;
    border-radius: 5px;
    border: 1px solid #d6cfe8;
    padding-left: 140px;
    padding-top: 12px;
    &::file-selector-button { display: none;}
    &:before {
      content: "Choose File...";
      display: inline-block;
      padding: 12px 15px;
      position: absolute;
      width: 128px;
      height: 100%;
      background: #ffffff;
      color: #4b3987;
      font-weight: bold;
      opacity: 1;
      margin-top: -12px;
      margin-left: -140px;
      border-right: 1px solid #d6cfe8;
    }
  }
}
.bordered-table {
  border-collapse: collapse; margin: 20px 0;
  td, th { border: 1px solid #bcbbbb; padding: 5px 10px; }
}
ol.letter {
  list-style: none;
  > li { text-indent: -20px; margin-bottom: 15px;}
}
.coupon-wrap {
  border: 1px solid #92b0c5;
  padding: 10px;
  border-radius: 5px;
  background: #78b7e4;
  position: relative;
  color: #fff !important;
  .remove {
    position: absolute;
    right: -15px;
    top: -20px;
    background: #F44336;
    padding: 5px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    line-height: 18px;
    cursor: pointer;
  }
}

//UL SELECT PLAN
ul.select-a-plan {
  padding: 0;color:#505F98;
  > li {
    display: flex; border: 1px solid #a2d0e2; border-radius: 5px;margin-bottom:20px;
    .radio-wrap {
      width: 50px; display: flex; justify-content: center; align-items: center; padding: 10px; border-right: 1px solid #a2d0e2;
      .checkbox { height: 25px; }
    }
  }
}

//TABLE WRAP
.wrap {
  color: #505F98;
  &.order-reference { width: 100% !important; }
  width: calc(100% - 50px);
  > .list-wrap {
    display:flex;
    > div { width: 50% ;}
    .image {
      display: flex;align-items: center;justify-content: center;
    }
    .pricing {
      background: #eaf7f8;
      .price-wrap {
        padding: 10px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        > * { display: block;}
        .price {
          font-size: 3rem; font-weight: 500;display:flex; align-items: center; line-height: 5rem;
          .currency { font-size: 30px; margin-right: 10px; display: inline-block;}
        }
        .price-desc { text-align: right; display: block; font-size: 20px;}
      }
      .frequency { width: 100%;background: #64b2eb; display: block; padding: 8px 10px; color: #fff; text-align: center;text-shadow: none;}
    }
  }
  > .table-wrap {
    overflow-x: scroll;
    > table {
      border-collapse: collapse; background: #f0f3f5;
      tr td { padding: 20px; text-align: center; height: 120px; }
      thead {
        background: #64aadc;text-align:center;
        tr td { width: 25%; background: #64aadc;}
        tr td:nth-child(2) { background: #78b7e4; }
        tr td:nth-child(4) { background: #3f8bc1; }
        * { color: #fff;font-weight:bold;text-shadow:none;outline:none; }
      }
      tbody {
        tr td:nth-child(1), tr td:nth-child(3) { background: #f6f9fa; }
      }
    }
    .vetxpand-add-ons {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      * { font-size: 16px;}
      .desc-wrap {
        .desc {
          display: grid;
          grid-template-columns: 3fr 1fr 1fr;
          background: #f6f9fa;
          align-items: center;
          height: auto;
          > div { margin: 0; height: 100%; border: 1px solid #b7dae8; padding: 5px 10px; }
          > div:nth-child(2), > div:nth-child(3) { }
        }
        .desc.header {
          * { color: #fff; }
          background: #64aadc;
          > div { border: none; }
          > div:nth-child(2) { background: #3f8bc1 ;}
          > div:nth-child(3) { background: #7063ac; }
        }
      }
    }
  }
}

.row__item {
  .inputlabel {
    label {
      color:#505F98;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .textinput__input, .dateinput__input {
    border-color: #d6cfe9;
    padding: 10px;
    font-size: 20px;
    &:focus-visible {
      box-shadow: none;
      outline: none;
      background: #f1eff1;
    }
  }
}
.clinic-logo {
  align-items: center;
  column-gap: 25px;
  flex-wrap: wrap;
  display: flex;
}
.onboarding {
  padding-bottom: 40px;
  * { font-family: 'Atten New'; }
  &.isloading .content-box {
    &:before {
      display: block;
      content: "Loading...";
      width: 100%;
      height: 100%;
      position: absolute;
      background: #ffffffdb;
      z-index: 2;
      text-align: center;
      font-size: 2rem;
      vertical-align: middle;
      color: transparent; //#0067b0;
      line-height: 50vh;
      letter-spacing: 3px;
      text-transform: uppercase;
      left: 0;
      top: 0;
      z-index: 2;
      background: url("https://vetxtend.com.au/wp-content/uploads/2024/12/loading.webp") no-repeat;
      background-position: center center;
      background-color: #fff;
      background-size: 250px;
      opacity: 0.5;
    }
  }
  p, span { color: #505F98; }
  .inputerror { color: #dd2222 }
  
  .pull-right { float: right; }
  a { color: #505F98; font-weight: normal; &.action { font-size: 18px; }}
  
  ul.list {
    padding: 0;
    &.check {
      padding-bottom: 10px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        display: flex;
        column-gap: 10px;
        align-items: center;
        img.icon { width: 15px; }
        &:before {
          /*content: "✓";*/
          border: 1px solid;
          width: 20px;
          height: 20px;
          display: inline-block;
          text-align: center;
          line-height: 20px;
          border-radius: 10px;
          background: #63a8ad;
          color: #fff;
          margin-right: 5px;
        }
      }
    }
  }
  .image-icon {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #64B3EB;
    padding: 10px;
    border-radius: 50px;
    img {
      width: 50px !important;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      font-weight: bold;
      font-size: 18px;
      span {
        font-weight: 100;
      }
    }
    input {
      margin-top: 10px;
      padding: 10px;
      font-size: 20px;
      border-radius: 6px;
      border: 1px solid #d6cfe8;
    }
  }
  .providedradio {
    .inputlabel {
      font-weight: bold;
    }
    .providedradio__options {
      display: flex;
      column-gap: 15px;
      .providedradio__button {
        button {
          border-radius: 5px;
          border: none;
          padding: 12px;
        }
        span {
          color:#fff;
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
  }
  .dropdowninput-default {
    .inputlabel {
      label {
        font-weight: bold;
      }
    }
    .dropdowninput__input {
      padding: 3px 0;
      border-color: #d6cfe9;
    }
  } 
  
  .checkbox {
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
    border: 1px solid #505F98;
    cursor: pointer;
    border-radius: 15px;
    &.active {
      color: #fff;
      position: relative;
      background: #505F98;
      overflow: hidden;
      &:before {
        position: absolute;
        content: " ";
        left: 2px;
        right: 0;
        margin: auto;
        top: 3px;
        width: 40px;
        height: 40px;
        background: url("https://thrivingpets.com.au/wp-content/themes/thrivingpets/css/images/icon-check.png") 0 0 no-repeat;
      }
    }
    &.square {
      border-radius: 5px;
    }
  }
  .banner {
    height: 469px;
    background: url("https://cdn.shopify.com/s/files/1/0579/5712/2230/files/banner-couple-with-dog.png?v=1716441161");
    //background: url("https://cdn.shopify.com/s/files/1/0579/5712/2230/files/banner-1_c38e0660-bfc0-48c5-a182-62884b22df5a.jpg?v=1713834856");
    width: 100%;
    background-position: center 35%;
    background-size: cover;
  }
  > .content {
    width: 1000px;
    max-width: 100%;
    background: #fff;
    margin: -136px auto;
    border-radius: 15px;
    margin-bottom: 60px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 7px -2px #727272;
    p {
      font-size: 18px;
    }
    .steps {
      background: #64B3EB;
      padding: 20px 60px;
      display: flex;
      justify-content: space-between;
      border-radius: 15px 15px 0 0;
      min-height: 136px;
      * {
        color: #fff;
        font-weight: 100;
      }
      h3 {
        font-size: 30px;
      }
      h5{
        font-size: 20px;
      }
    }
    ul.steps-menus {
      width: 100%;
      padding-left: 0;
      margin-top:0;
      &:after {
        content: " ";
        display: block;
        clear: both;
      }
      li {
        float: left;
        list-style: none;
        margin-right: 10px;
        padding: 10px 5px;
        &.active {
          border-bottom: 1px solid #57a8ac;
          font-weight:bold;
          a { 
            font-weight: bold;
          }
        }
        a { 
          text-decoration: none;
          color: #505F98;
          font-size: 16px;
        }
      }
    }
    .content-box {
      position: relative;
      padding:20px 60px;
      .checkout-list {
        padding-left: 0;
        margin-bottom: 40px;
        li {
          list-style: none;
          padding: 20px 0;
          display: flex;
          column-gap: 15px;
          border-bottom: 1px solid #c8c1df;
          position: relative;
          img {
            max-width: 100%;
            width: 80px;
            height: 80px;
            &.trash { 
              border-radius: 0px;
              position: absolute;
              top: -10px;
              right: 0;
              width: 20px;
              cursor: pointer;
            }
          }
        }
        &.unsubscribe-pets {
          li { 
            padding: 20px;
            background: #fff3f3;
            border-bottom: 2px solid #ff6363;
            * { color: #ff6363; }
          }
        }
      }
      .checkout-calculation {
        padding-left: 0;
        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          column-gap: 16px;
          span.currency {
            font-size: 20px;
          }
          > table {
            width: 500px;
            max-width: 100%;
            border-collapse: collapse;
            tr {
              td {
                padding: 10px 0px;
              }
              td:last-child {
                text-align: right;
              }
            }
            
            tbody {
              border-bottom: 2px solid #c8c1df;
              
            }
          }
        }
      }
      .checkout-total {
        max-width: 100%;
      }
      .checkout-options {
        padding-left: 0;
        width: 600px;
        max-width: 100%;
        li {
          list-style: none;
          background: #f5f3f9;
          border-radius: 10px;
          margin-bottom: 20px;
          align-items: center;
          //&:not(:first-child) {
            display: flex;
            padding: 10px 20px;
            column-gap: 15px;
            border: 1px solid #ddd;
          //}
        }
        .credit-form {
          padding: 0;
          border: 1px solid #ddd;
          border-radius: 10px;
          overflow: hidden;
          h4 {
            padding: 20px;
            margin: 0;
            border-bottom: 1px solid #ddd;
          }
          .fields {
            padding: 20px;
            background: #fafafa;
          }
        }
      }
    }
  }
  .membership-tab-pricing {
    p.title-1 {
      font-weight: bold;
      font-size: 20px;
    } 
    ul.tab {
      padding: 10px 10px 0;
      text-align:center;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #57a8ac;
      &:after {
        content: " ";
        display: block;
        clear: both;
      }
      li {
        float: left;
        list-style: none;
        padding: 20px;
        background: #b9dcdd;
        color: #fff;
        width: 50%;
        min-width: 120px;
        position: relative;
        cursor: pointer;
        &:nth-child(1) {
          border-radius: 10px 0 0 0;
          &:before {
            content: "SAVE 10%";
            display: inline-block;
            background: #c7201d;
            border-radius: 20px;
            font-size: 12px;
            padding: 2px 10px;
            color: #fff;
            position: absolute;
            width: 100px;
            top: -10px;
            left: 0;
            right: 0;
            margin: auto;
          }
          &:after {
            content: "most popular";
            display: inline-block;
            background: #505F98;
            border-radius: 0px 0 20px 20px;
            font-size: 10px;
            padding: 2px 10px 5px;
            color: #fff;
            position: absolute;
            width: 100px;
            bottom: -21px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        &:nth-child(2) {
          border-radius: 0 10px 0 0;
        }
        &.active {
          background: #57a8ac;
        }
      }
    }
    .content {
      padding-top: 10px;
      .total {
        &.amount {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-size: 20px;
          .price {
            font-size: 48px;
            display: inline-block;
            text-align: center;
            font-weight: 500;
            .regular {
              display: block;
              font-weight: 100;
              font-size: 20px;
              text-decoration: line-through;
            }
          }
        }
        &.desc {
          display: flex;
          justify-content: space-evenly;
          padding: 20px;
          border-top: 2px solid #dfdfef;
          border-bottom: 2px solid #dfdfef;
          font-size: 20px;
          .label {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
    &.simple {
      ul.tab {
        padding: 0;
        border: none;
        display: flex;
        column-gap: 10px;
        li {
          padding: 0;
          border-radius: 5px;
          opacity: 0.8;
          position: relative;
          &:nth-child(1):after, &:nth-child(1):before { display: none; }
          .pricing {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            background: #ebf7f8;
            align-items: center;
            &.yearly {
              &:before {
                content: 'save 10%';
                background: #c7201d;
                position: absolute;
                top: -10px;
                left: 0;
                right: 0;
                margin: auto;
                width: 100px;
                border-radius: 20px;
                padding: 2px 10px;
                font-size: 12px;
                text-transform: uppercase;
              }
            }
            span {
              &:nth-child(1) { font-size: 30px ;}
              &:nth-child(2) { 
                font-size: 48px;
                font-weight: bold;
                .subtext {
                  font-size: 20px;
                  display: block;
                  font-weight: normal;
                  &.strike {
                    text-decoration: line-through;
                  }
                }
              }
              &:nth-child(3) { font-size: 20px; }
            }
          }
          .label {
            padding: 10px;
            display: flex;
            text-align: center;
            flex-direction: column;
            cursor: pointer;
            align-items: center;
            min-height: 70px;
            justify-content: center;
            span {
              color: #fff;
            }
            cursor: pointer;
            .subtext {
              display: block;
              font-size: 14px;
              color: #fff;
              font-weight: 100;
            }
          }
          &.active {
            opacity: 1;
            &:before {
              content: "✓";
              display: inline-block;
              border-radius: 20px;
              background: #63a8ad;
              color: #fff;
              right: 5px;
              left: auto;
              top: 4px;
              padding: 0;
              width: 20px;
              height: 20px;
              position: absolute;
              font-size: 14px;
            }
          }
        }
      }
    }
    &.pricing {
      ul.tab {
        border-bottom: none;
        padding-bottom: 20px;
        li {
          background: transparent;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          &:first-child {
            border-right: 1px solid #afe8f0;
            .label {
              position: relative;
              &:after {
                content: "most popular";
                position: absolute;
                color: #fff;
                background: #505F98;
                font-size: 8px;
                line-height: 12px;
                padding: 2px 3px;
                font-weight: normal;
                text-transform: uppercase;
                bottom: -20px;
                left: 0;
                right: 0;
                margin: auto;
                border-radius: 0 0 20px 20px;
                width: 80%;
              }
            }
          }
          &:before {
            bottom:0;
            top:auto;
          }
          &:after, &:before { display: none; }
          .label {
            font-size: 18px;
            font-weight: bold;
            border-bottom: 2px solid #505F98;
            line-height: 50px;
            margin-bottom: 20px;
          }
          .costing {
            display: flex;
            flex-direction: column;
            span {
              font-size: 14px;
            }
            .price {
              font-size: 48px;
              vertical-align: top;
              position: relative;
              .currency {
                display: inline-block;
                font-size:20px;
                font-weight: normal;
                position: absolute;
                top:5px;
                margin-left: -15px;
              }
            }
            .cycle {
              margin-bottom: 20px;
              font-size: 20px;
            }
            .saver {
              background: #c7201d;
              border-radius: 20px;
              color: #fff;
              padding: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .box.card {
    background: #f9f8ff;
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 11px -5px #7a6f6f;
    padding-bottom: 40px;
    margin-bottom: 20px;
    min-height: 1050px;
    .header {
      background: #505F98;
      text-align: center;
      font-weight: 500;
      font-size: 30px;
      height: auto;
      line-height: 90px;
      color:#fff;
      margin-bottom: 0;
    }
    .card-content {
      padding: 20px 40px;
      .icon-content {
        display: flex;
        column-gap: 20px;
        color: #505F98;
        padding: 0;
        border-bottom: 1px solid #c5bedf;
        position: relative;
        min-height: 120px;
        &.or-option {
          border-bottom: none;
          &:after {
            content: "or";
            display: block;
            position: absolute;
            bottom: -12px;
            left: 40%;
            font-weight: bold;
            background: #9284c1;
            color: #fff;
            padding: 4px 5px;
            border-radius: 20px;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 20px;
          }
        }
        img.icon {}
        .icon-text {
          font-size: 20px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .subtext {
            display: block;
            font-size:16px;
            font-weight: 100;
          }
        }
      }
    }
    
    &.secondary {
      .header {
        background: #57a8ac;
      }
    }
  }
  .captcha {
    width: 100%;
    height: 100vh;
    margin: auto;
    position: fixed;
    top: 0;
    background: #22222282;
    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 25px;
      cursor: pointer;
    }
    .content-box {
      position: relative;
      width: 700px;
      background: #fff;
      padding: 40px;
      border-radius: 15px;
      margin: 5% auto;
    }
  }
  &.page {
    h4, h5, p, ol{
      margin: 0;
      margin-bottom: 20px;
    }
    * { font-size: 18px; line-height:30px; color:#505F98; }
    h4 { font-size: 24px; }
    h5 { font-size: 20px; }
  }
  &.inclusions { padding: 20px 20px 0 20px; }

  
}
@media only screen and (max-width: 976px) {
  .btn {
    &.show-filter { display: block; width: 200px; }
  }
}
@media only screen and (max-width: 676px) {
  .onboarding {
    .flex { flex-direction: column; }
    * { max-width: 100%; }
    .big {
      font-size: 60px;
      line-height: 60px;
    }
    .box.card {
      .card-content {
        padding: 20px;
      }
    }
    > .content {
      .steps {
        flex-direction: column;
        min-height: 0;
        img { height: 80px;}
        h2, h3 { font-size: 24px; line-height: 24px;}
        * { margin: 10px; }
      }
      ul.steps-menus {
        display: flex;
        align-items: center;
        background: #64b3eb;
        border-radius: 5px;
        overflow: hidden;
        li {
          margin-right: 0;
          height: 60px;
          display: flex;
          align-items: center;
          padding: 10px;
          text-align: center;
          a { color: #fff; font-size: 10px; text-transform: uppercase; }
          &:hover, &.active { background: #505F98 ;}
        }
      }
      .membership-tab-pricing {
        &.simple {
          ul.tab li{
            .pricing {
              flex-direction: column;
              span {
                .subtext {
                  display: none;
                }
              }
            }
          }
        }
      }
      .content-box {
        padding: 20px;
        * { max-width: 100%; }
        .checkout-calculation {
          li { flex-direction:column; }
        }
      }
    }
    &.inclusions {
      padding: 0px;
      .flex { flex-direction: column; }
      * { max-width: 100% };
    }

    ul.select-a-plan {
      > li {
        > .wrap {
          > .list-wrap {
            flex-direction: column;
            > div { width: 100%; }
            > div.image { padding: 20px;}
            .price-wrap { flex-direction: column; align-items: center !important; }
          }
          > .table-wrap { overflow-x: scroll; }
        }
      }
    }
    /*.download-qrcode {
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        > div { margin: 15px 0;}
        .btn-wrapper.link {
          font-size: 16px;
          .img-wrap { display: none; }
        }
      }
    }*/
  }
}

