@import "../../../styles/variables";

.accordian {
  margin-top: $margin-top-medium;
  background-color: $white;
  padding: 0px;
  border-radius: $border-radius-small;

  &__header {
    padding: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .accordian__icon {
      width: 80px;
      height: 80px;
      margin-right: 30px;
      @include xs-screen {
        display: none;
      }
    }

    .accordian__title {
      flex: 1;
      flex-grow: 1;
      margin: 0;
      font-weight: normal;
    }

    .accordian__chevron {
      width: 12px;
      height: 12px;
      border-top-width: 3px;
      border-top-style: solid;
      border-top-color: $purple-primary;
      border-right-width: 3px;
      border-right-style: solid;
      border-right-color: $purple-primary;

      &-open {
        transform: rotate(315deg);
      }

      &-closed {
        transform: rotate(225deg);
      }
    }
  }

  &__body {
    // Fixed height required for animation
    height: auto;

    &-closed {
      padding: 0px 20px 0px 20px;
      height: 0px;
      overflow-y: hidden;
    }

    &-open {
      padding: 0px 20px 20px 20px;
      overflow-y: auto;
    }

    .accordian__content {
      > *:first-child {
        margin-top: 0px;
      }

      > *:last-child {
        margin-bottom: 0px;
      }

      &-closed {
        padding: 0px 10px;
        opacity: 0;
        height: 0;
      }

      &-open {
        padding: 0px 10px;
        opacity: 1;
        overflow-y: auto;
      }
    }
  }

  &__fixedlink {
    font-weight: bold;
    color: $purple-primary;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    // border: 1px solid tomato;

    &-open {
      height: 38px;
      opacity: 1;
      padding: 8px;
      margin-top: -16px;
    }

    &-closed {
      height: 0px;
      overflow: hidden;
      margin-top: 0px;
      opacity: 0;
      padding: 0px;
    }
  }
}


/** Style override for accordian nesting **/
.accordian__content {
  .accordian {
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-light;

    &:hover {
      border-color: $purple-secondary;
    }
  }
  .accordian__header {
    padding: 14px 10px;

    .accordian__title {
      font-size: $font-size-h3;
    }
  }
  .accordian__body-closed {
    padding: 0px;
  }
  .accordian__body-open {
    padding: 0px 0px 10px 0px;
  }
}
