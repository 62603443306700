@import "../../../styles/variables";

.dropdowninput {

    &-default {
        @include input-wrap;

        div {
            font-family: "Atten New";
        }

        .dropdowninput__input {
            @include input;
            padding: 0px;
        }
    }


    &-borderless {
        .dropdowninput {
            margin: 0px;
            padding: 0px;
        }

        div {
            font-family: "Atten New";
        }

        .dropdowninput__input {
            @include input;
            border-color: $white;
            padding: 0px;
            margin: 0px;
        }
    }
}