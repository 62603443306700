@import "../../../styles/variables";

.header {
  width: 100%;
  background-color: $purple-primary;
  color: $white;
  height: $menu-vertical-height;
  * { font-family: 'Atten New'; }
  &__container {
    @include container;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .container {
      position: relative;
      .flex {
        display: flex;
        justify-content: space-between;
      }
      .sidebar {
        position: absolute;
        right: 0;
        span.profileName {
          display: flex;
          width: 270px;
          flex-direction: column;
          justify-content: center;
          .title {
            display: block;
            font-size: 24px;
            font-weight: bold;
          }
          .name {
            line-height: 16px;
            font-size: 16px;
          }
          * { color: #fff; }
        }
        .sidebar__nav { 
          background: #fff;
          padding: 0px 15px 0px;
          border-radius: 0 0 15px 15px;
          overflow: hidden;
          a.sidebar__link {
            font-size: 18px;
            color: #505F98;
            border-bottom: 1px solid #e2f0f7;
            padding: 12px 0;
            display: flex;
            align-items: center;
            grid-column-gap: 15px;
            column-gap: 15px;
            .sidebar__linkicon {
              max-width: 1000px;
              max-height: 1000px;
              width: 60px;
              height: 60px;
            }
          }
          li:nth-child(5){
            border-bottom: 1px solid #cabed5;
            width: calc(100% + 60px);
            padding: 0px 30px;
            margin-left: -30px;
            a.sidebar__link { border: none;}
          }
          li:nth-child(6), li:nth-child(7), li:nth-child(8), li:nth-child(9) {
            width: calc(100% + 60px);
            padding: 0px 30px;
            margin-left: -30px;
            font-weight: bold;
            background: #f8f5fb;
          }
          li:last-child {
            a.sidebar__link {
              border: none;
            }
          }
        }
        .sidebar__ui {
          position: absolute;
          border-radius: 0;
          max-width: 400px;
          .sidebar__exit {
            position: relative;
            img {
              position: absolute;
              top: calc(50% - 10px);
              right: 10px;
            }
            &:before, &:after {
              display: none;
            }
          }
        }
        &.sidebar-close { display: none;}
      }
    }
    .header__logo {
      flex: 0;
      height: calc(#{$menu-vertical-height} - (#{$menu-vertical-padding} * 2));
      margin: $menu-vertical-padding 0px;

      img {
        height: 100%;
      }
    }

    .header__nav {
      float: right;
      height: $menu-vertical-height;
      @include sm-screen {
        display: none;
      }

      .header__ul {
        margin: 0px;
        padding: 0px;
        width: 100%;

        .header__li {
          cursor: pointer;
          display: inline;
          padding: 0px 6px;
          height: $menu-vertical-height;
          display: inline-block;
          background-color: $purple-primary;
          &:hover {
            background-color: $purple-secondary;
          }

          .header__link {
            color: $white;
            height: $menu-vertical-height;
            display: table-cell;
            white-space: nowrap;
            width: 1px;
            padding: 10px;
            padding-right: 30px;
            vertical-align: middle;
          }

          .header__linkicon {
            @include menu-icon;
          }
        }
      }
    }

    .header__sidebartrigger {
      /*height: $menu-vertical-height;
      width: 70px;
      position: relative;
      cursor: pointer;
      display: none;
      transform: translateX($container-horizontal-padding);*/
      cursor: pointer;
      @include sm-screen {
        display: block;
      }

      /*div {
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: $white;
        left: 25px;
      }*/
      span.initialIcon {
        font-size: 40px;
        display: flex;
        column-gap: 10px;
        height: 70px;
        align-items: center;
        span {
          background: #51429a;
          font-size: 20px;
          color: #fff;
          border-radius: 50px;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
        }
      }
    }
  }
}
