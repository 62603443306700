@import "../../../styles/variables";

.accordianlookingbutton {
  margin-top: $margin-top-medium;
  background-color: $white;
  padding: 0px;
  border-radius: $border-radius-small;
  padding: 30px 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__title {
    flex: 1;
    flex-grow: 1;
    margin: 0;
    font-weight: normal;
    font-size: $font-size-h2;
    color: $purple-primary;
    font-family: "Montserrat";
  }

  &__chevron {
    width: 12px;
    height: 12px;
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: $purple-primary;
    border-right-width: 3px;
    border-right-style: solid;
    border-right-color: $purple-primary;
    transform: translateX(-3px) rotate(45deg);
  }
  .accordian__icon {
    width: 80px;
    margin-right: 30px;
    img {
      width: 100%;
    }
  }
}


/** Style override for accordian nesting **/
.accordian__content, .card {
  .accordianlookingbutton {
    margin-top: 10px;
    padding: 14px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-light;

    &:hover {
      border-color: $purple-secondary;
    }
    
    &__title {
      font-size: $font-size-h3;
    }
  }
}
@media only screen and (max-width: 467px) {
  .accordianlookingbutton {
    .accordian__icon {
      display: none;
    }
  }
}