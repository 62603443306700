@import "../../../styles/variables";

@mixin disabled {
  opacity: 0.7;
  cursor: default;
}

.button {
  border-radius: $border-radius-small;
  margin-top: $margin-top-medium;
  margin-right: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  border-width: 2px;
  border: 3px solid $purple-primary;
  padding: 14px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  &-active {
    &:hover {
      opacity: 0.9;
    }
  }
  .spinner {
    display: none;
  }
  &-loading {
    @include disabled;
    .spinner {
      display: inline-block;
      width: auto;
      height: 50px;
      min-height: auto;
      width: 50px;
      left: 0%;
      transform: scale(0.3);
  
      &__bar::after {
        background-color: white;
      }
    }
  }

  &-primary {
    color: $white;
    background-color: $purple-primary;
    border-color: $purple-primary;
  }

  &-secondary {
    color: $white;
    background-color: $purple-secondary;
    border-color: $purple-secondary;
  }

  &-danger {
    color: $white;
    background-color: $red;
    border-color: $red;
  }

  &-warning {
    color: $white;
    background-color: $yellow;
    border-color: $yellow;
  }

  &-fullwidth {
    width: 100%;
    margin-right: 0px;
  }

  &-rounded {
    border-radius: 25px;
  }

  &-disabled {
    @include disabled; 
  } 
}
