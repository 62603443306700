@import "../../../styles/variables";

.featurelist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__item {
        margin-top: 10px;

        .featurelist__icon {
            flex: 0;
            flex-basis: 60;
        }
        .featurelist__text {
            flex: 1;
            padding-left: 10px;
        }
    }
}