@import "../../../styles/variables";

.cart {
  // position: fixed; // For cart sidebar
  top: 0;
  left: 0;
  height: 100%;
  // width: 350px; // For cart sidebar
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(-100%);
  transition: transform 0.15s ease-in-out;
  color: $purple-primary !important;



  .cart-close {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 35px;
    color: #999;
    border: none;
    background: transparent;
    transition: transform 100ms ease;
    cursor: pointer;
  }

  .cart-header {
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
    flex: 0 0 auto;
    display: inline-block;
  }

  .cart-line-items {
    flex: 1 0 auto;
    margin: 0;
    padding: 20px;
  }

  .cart-footer {
    padding: 20px;
    border-top: 1px solid #e5e5e5;
    flex: 0 0 auto;
  }

  .cart-checkout {
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .cart-info {
    padding: 15px 20px 10px;
  }

  .cart-info-total {
    float: left;
    text-transform: uppercase;
  }

  .cart-info-small {
    font-size: 11px;
  }

  .cart-info-pricing {
    float: right;
  }

  .pricing {
    margin-left: 5px;
    font-size: 16px;
    color: black;
  }
}

.cart--open {
  transform: translateX(0%);
  z-index: 1;
}