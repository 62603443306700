@import "../../../styles/variables";

.profileimage {
    float: left;
    margin-right: 30px;

    &-clickable {
        cursor: pointer;
    }

    &__profile {
        height: 75px;
        width: 75px;
        border-width: $border-width-thick;
        border-style: solid;
        border-color: $teal-primary;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $gray-mid;
    }

    &__label {
        color: $teal-primary;
        font-weight: 500;
        text-align: center;
        width: 100%;
        border-color: $teal-primary;
    }
}