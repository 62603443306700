@import "./variables";

/* Import fonts */
@font-face {
  font-family: "Atten New";
  src: url("../assets/fonts/AttenNew-Medium.eot");
  src: url("../assets/fonts/AttenNew-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AttenNew-Medium.woff") format("woff"),
    url("../assets/fonts/AttenNew-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Atten New";
  src: url("../assets/fonts/AttenNew-Regular.eot");
  src: url("../assets/fonts/AttenNew-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AttenNew-Regular.woff") format("woff"),
    url("../assets/fonts/AttenNew-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Regular.eot");
  src: url("../assets/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-SemiBold.eot");
  src: url("../assets/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Bold.eot");
  src: url("../assets/fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Override defaults */
*,
*::before,
*::after {
  box-sizing: border-box;
  transition: 0.5s;
  font-family: "Atten New";
}

/* Minimum height is height of window */
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  transition: 0s !important;
}

/* Typography */
* {
  font-family: "Atten New";
  font-size: $font-size-default;
}
h1, h2, h3, h4, h5 {
  font-family: "Atten New";
  color: $purple-primary;
  font-weight: 600;
}
h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
p {
  &.error { color: $red }
}
/* Inputs */
fieldset {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.title {
  .subtitle {
    font-size: 18px;
    font-weight: normal;
    display: block;
  }
}