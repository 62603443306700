@import "../../../styles/variables";

.article {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  border-top: 1px solid $gray-light;
  align-content: center;

  &__image {
    flex: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    @include lg-screen {
      flex-basis: 120px;
      width: 120px;
      height: 120px;
      min-width: 120px;
    }
    @include sm-screen {
      flex-basis: 80px;
      width: 80px;
      height: 80px;
      min-width: 80px;
    }
  }

  &__title {
    margin: 0px;
    padding: 0px 20px;
    align-self: center;
  }
}
