@import "../../../styles/variables";

$tab-height: 62px;
$tab-gap-width: 8px;

.tabbedcard {
  display: flex;
  flex-direction: column;

  &__nav {
    margin-top: $margin-top-medium;
    margin-bottom: -1px;
    z-index: 1;

    .tabbedcard__ul {
      padding: 0;
      margin-bottom: 0px;
      margin-top: 0px;
      display: flex;
  
      .tabbedcard__li {
        border-radius: $border-radius-small;
        display: block;
        float: left;
        padding: 20px 12px;
        color: $white;
        cursor: pointer;
        color: $purple-primary;
        font-weight: 600;
        font-size: $font-size-h3;
        background-color: $white;
        border-bottom-width: $tab-gap-width;
        margin-right: calc(#{$tab-gap-width} - 1px);
        margin-bottom: $tab-gap-width;
        height: $tab-height;
        border-radius: $border-radius-large;
        transition: 0s;
  
        &-active {
          border: $border-width-thin solid $teal-primary;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          height: calc(#{$tab-height} + #{$tab-gap-width});
          margin-bottom: 0px;
          border-bottom-width: 0px;
        }
      }
    }
  }

  > .card {
    border: $border-width-thin solid $teal-primary;
    min-height: 300px;
  }

  .productcard {
    border: 1px solid $gray-mid;
  }
}
