@import "../../../styles/variables";

.downloadtheapp {
  &__heading {
    text-align: center;
  }

  &__downloadgrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include sm-screen {
      flex-direction: column;
    }

    .downloadtheapp__qr {
      width: 100px;
      height: 100px;
    }

    .downloadtheapp__or {
      padding: 20px;
    }

    .downloadtheapp__appstores {
      max-width: 150px;
      width: 100%;

      > * {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
