@import "../../../styles/variables";

.appointment {
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  border-color: $gray-mid;
  display: flex;
  flex-direction: row;
  padding: $component-internal-padding;
  @include md-screen {
    flex-direction: column;
  }

  &__content {
    flex: 1;

    * {
      margin-top: $margin-top-small;
      margin-bottom: $margin-top-small;
    }

    a {
      cursor: pointer;
    }
  }

  &__buttons {
    flex: 1;
    max-width: 300px;
    align-self: center;
    @include md-screen {
      margin-top: $margin-top-medium;
      align-self: flex-start;
    }

    * {
      margin: 0px;
    }
    button {
      background-color: $teal-primary;
      border: 3px solid $teal-primary;
    }
  }
}
