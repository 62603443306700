@import "../../../styles/variables";

.card {
  background-color: $white;
  border-radius: $border-radius-small;
  padding: $content-container-padding;

  > *:first-child {
    margin-top: 0px;
  }

  > *:last-child {
    margin-bottom: 0px;
  }
}
