@import "../../../styles/variables";

.phoneinput {
  display: flex;
  flex-direction: row;

  > *:first-child {
    flex-basis: 135px;
    margin-right: $margin-between;
  }

  > *:last-child {
    flex: 1;
  }
}
