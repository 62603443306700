@import "../../../styles/variables";

.footer {
  width: 100%;
  background-color: #f3fafc;
  color: #505f98;
  // position: absolute;
  // bottom: 0;

  &__row {
    display: flex;
    justify-content: space-between;
    //padding: 40px 0px;
    padding: 30px 0px;
    @include sm-screen {
      flex-direction: column;
    }

    .footer__col {
      width: 270px;
      padding: 20px;
      .footer__phone {
        display: flex;
        text-decoration: none;
        margin-top: #{$margin-top-medium};
        align-items: center;
        &icon {
          @include menu-icon;
        }
        &label {
          font-size: 18px;
          color: #505f98;
          &:hover {
            color: #64b3eb;
          }
        }
      }

      .footer__social {
        display: block;
        margin-top: #{$margin-top-medium};
      
        &icons {
          max-width: 20px;
          max-height: 20px;
          margin-right: #{$margin-between};
          transform: translateY(2px);
        }
        
      }

      h4 {
        margin-block-start: 0;
        margin-block-end: 0;
        color: #505f98;
        font-size: 20px;
        display: flex;
        align-items: center;
        column-gap: 15px;
        * { color: #505f98; }
      }

      img {
        max-width: 200px;
        max-height: 60px;
      }

      ul {
        margin: 0px;
        margin-top: 10px;
        padding: 0px;
        width: 100%;

        li {
          width: 100%;
          display: block;
          cursor: pointer;

          a {
            display: block;
            color: #505f98;
            padding: 4px 0px;
            font-size: 20px;
            font-weight: 500;
            &:hover {
              color: #64b3eb;
            }
          }
        }
      }
      

    }
  }
  .container {
    &.border-d2 {
      text-align: center;
      padding: 60px;
      border-bottom: 1px solid #fff;
      position: relative;
      img { height: 100px;max-width: 100%;}
      &:after {
        content: " ";
        display: block;
        width: 50px;
        height: 20px;
        background: $purple-primary;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        margin: auto;
      }
    }
  }
}
