@import "../../../styles/variables";

.providedradio {
  margin-top: $margin-top-medium;


  &__button {
    background-color: transparent;
    padding: 0px;
    width: 100%;
    .button {
      @include input;
      border-radius: 0px;
      &-secondary {
        font-weight: normal;
      }
    }
  }

  &--disabled {
    .button {
      opacity: 0.7;
      border-color: $white;
    }
    .button:hover {
      opacity: 0.7;
      cursor: default;
      border-color: $white;
    }
  }

  &__text-false {
    font-size: $font-size-default;
    color: $purple-primary;
  }
  &__text-true {
    font-weight: bold;
    font-size: $font-size-default;
    color: $purple-primary;
  }
  &__options {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    border-radius: 5px;
  }
  
}
