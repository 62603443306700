@import "../../../styles/variables";

.backlink {
    color: $purple-primary;
    margin: 0px;
    display: block;
    float: left;
    cursor: pointer;
    padding: 6px;
    padding-left: 0px;

    &:hover {
      color: $purple-secondary;
    }
}