@import "../../../styles/variables";

.overflow {
  overflow: auto;
  white-space: nowrap;
  margin-left: -$container-horizontal-padding;
  margin-right: -$container-horizontal-padding;
  padding-left: $container-horizontal-padding;
  padding-right: $container-horizontal-padding;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent;
  }

  &__child {
    display: inline-block;
  }
}
