@import "../../../styles/variables";

.claim {
  border-width: 1px;
  border-style: solid;
  border-color: $purple-secondary;
  background-color: $teal-secondary;
  border-radius: $border-radius-large;
  padding: $component-internal-padding;
  display: flex;
  flex-direction: row;
  margin-top: $margin-top-medium;
  @include sm-screen {
    flex-direction: column;
  }

  &__content {
    flex: 1;

    * {
      margin-top: $margin-top-small;
      margin-bottom: $margin-top-small;
    }
  }

  &__buttons {
    flex: 1;
    max-width: 150px;
    @include sm-screen {
      margin-top: $margin-top-medium;
    }

    * {
      margin: 0px;
    }
  }

  &__modal {
    display: flex;
    flex-direction: row;
    @include sm-screen {
      flex-direction: column-reverse;
    }
    
    &invoice {
      flex: 2;
      max-width: 480px;
    }

    &content {
      flex: 1;
    }
  }
}
