@import "../../../styles/variables";

@function calc-width($cols) {
  $template-width: "";
  $seperations: calc(#{$cols} - 1);
  $usable-width: calc(100% - (#{$seperations} * #{$margin-between}));
  $row-width: calc(#{$usable-width} / #{$cols});
  @for $i from 0 to $cols {
    $template-width: #{$template-width} #{$row-width};
  }
  @return $template-width;
}

.grid {
  display: grid;
  grid-column-gap: $margin-between;
  grid-row-gap: $margin-top-medium;
  margin: $margin-top-medium 0px;

  &-6 {
    @include lg-screen {
      grid-template-columns: calc-width(6);
    }
    @include md-screen {
        grid-template-columns: calc-width(4);
    }
    @include sm-screen {
      grid-template-columns: calc-width(3);
    }
    @include xs-screen {
      grid-template-columns: calc-width(2);
    }
  }

  &-4 {
    @include lg-screen {
      grid-template-columns: calc-width(4);
    }
    @include md-screen {
        grid-template-columns: calc-width(3);
    }
    @include sm-screen {
      grid-template-columns: calc-width(2);
    }
    @include xs-screen {
      grid-template-columns: calc-width(1);
    }
  }

  &-3 {
    @include lg-screen {
      grid-template-columns: calc-width(3);
    }
    @include md-screen {
      grid-template-columns: calc-width(2);
    }
    @include sm-screen {
      grid-template-columns: calc-width(2);
    }
    @include xs-screen {
      grid-template-columns: calc-width(1);
    }
  }

  &-2 {
    @include lg-screen {
      grid-template-columns: calc-width(2);
    }
    @include md-screen {
      grid-template-columns: calc-width(2);
    }
    @include sm-screen {
      grid-template-columns: calc-width(1);
    }
    @include xs-screen {
      grid-template-columns: calc-width(1);
    }
  }

  &__item {
    // border: 1px solid tomato;
  }
}
