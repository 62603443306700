@import "../../../styles/variables";

.codeInput {
  @include input;
  text-align: center;
  border: none;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
