@import "../../../styles/variables";

.plancard {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius-large;
  border: $border-width-thin solid $gray-mid;

  &__body {
    padding: $component-internal-padding;
    background-color: $purple-primary;
    position: relative;

    * {
      color: white;

      &:last-child {
          margin-bottom: 0px;
      }
    }

    .plancard__moreinfo {
        position: absolute;
        top: 8px;
        right: 8px;
        border: 2px solid $white;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
    }

    .plancard__logo {
      width: 80%;
      max-height: 44px;
      margin: 0px auto;
      float: none;
      display: block;
    }
  }

  &__footer {
    padding: $component-internal-padding;
    display: flex;

    > * {
      flex: 1;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: $margin-between;
      }

      p {
          margin: 0px;
          text-align: center;

          &:first-child {
              font-weight: bold;
              color: $purple-primary;
          }

          &:not(:first-child) {
            margin-top: $margin-top-small;
            font-size: $font-size-fineprint;
          }
      }
      
      .plancard__discountedtext {
        color: $red;
      }
    }
  }

  &__modal {
    * {
      text-align: center;
    }

    &logo {
      display: block;
      width: 80%;
      max-width: 300px;
      margin: 0px auto;
    }
  }
}
