@import "../../../styles/variables";

.stepper {
  &__progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 20px;
    margin-bottom: 40px;

    .stepper__step {
      flex: 0;
      flex-grow: 0;
      float: left;
      white-space: nowrap;
      color: $purple-primary;
      padding: 8px;
      font-size: $font-size-h4;
      font-weight: normal;
      font-family: "Atten New";

      &-active {
        font-weight: bold;
        border-bottom: 3px solid $teal-primary;
      }

      &-complete {
        font-weight: bold;
      }

      &-clickable {
        cursor: pointer;
      }
    }

    .stepper__seperator {
      font-size: $font-size-h4;
      padding: 8px;
      flex: 0;
      flex-grow: 0;
      font-weight: bold;
      color: $purple-secondary;

      &-travelled {
        color: $purple-primary;
      }
    }
  }

  &__content {
    width: 100%;
  }
}
