@import "../../../styles/variables";

.imagecropper {
  .modal__body {
      canvas {
          border: 1px solid black;
      }
  }

  &__scaleinput {
      width: 100%;
  }
}
