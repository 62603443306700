@import "../../../styles/variables";

.row {
  @include row;

  &--nospacing {
    
    > * {
      flex: 1;
      flex-grow: 0;
      
      button {
        white-space: nowrap;
      }
    }
  }
}
