@import "../../../styles/variables";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  &-close {
    left: auto;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.3;
  }

  &__ui {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: $sidebar-width;
    max-width: $sidebar-max-width;
    background-color: $purple-primary;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;

    &-open {
      right: 0px;
    }

    &-close {
      right: -$sidebar-max-width;
    }

    .sidebar__header {
      width: 100%;
      height: $menu-vertical-height;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px auto;

      .sidebar__logo {
        height: $menu-vertical-height;
        padding: $menu-vertical-padding 0px;
        flex: 1;
      }

      .sidebar__exit {
        height: $menu-vertical-height;
        width: 50px;
        flex-basis: 50px;
        min-width: 50px;
        flex: 0;
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
          content: "";
          width: 25px;
          height: 2px;
          position: absolute;
          background-color: $gray-mid;
          top: 50%;
          right: 16px;
        }

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }

    .sidebar__nav {
      padding: 10px 30px;

      .sidebar__ul {
        width: 100%;
        margin: 0px;
        padding: 0px;

        .sidebar__li {
          width: 100%;
          display: block;
          cursor: pointer;

          .sidebar__link {
            display: block;
            color: $white;
            padding: 18px;

            .sidebar__linkicon {
              @include menu-icon;
            }
          }
        }
      }
    }
  }
}
