/* Colours */
$red: #dd2222;
$yellow: #FFC107;
$white: #ffffff;
$black: #000000;
$purple-primary: #64b3eb;
$purple-secondary: #505f98;
$teal-primary: #71cace; // hardcoded: DropdownInput.tsx
$teal-secondary: #e8f7f7;
$transparent: #00000000;
$gray-light: #64b3eb1a;
$gray-mid: #d8d8d8;
$gray-dark: #555555;
$blue: #64b3eb;
$dark-blue: #3f8bc1;

/* Font sizes */
$font-size-fineprint: 12px;
$font-size-default: 20px;
$font-size-h1: 34px;
$font-size-h2: 27px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-p: $font-size-default;
$font-size-label: $font-size-default;

/* Spacing */
$margin-top-small: 6px;
$margin-top-medium: 16px;
$margin-between: 16px;

/* Layout */
$sidebar-width: 85%;
$sidebar-max-width: 240px;
$container-width-max: 1100px;
$container-horizontal-padding: 20px; // Space between container and edge of screen
$section-vertical-padding: 60px; // Space between each section on a page
$content-container-padding: 30px; // Padding inside components that hold content
$component-internal-padding: 20px; // Padding used inside components (e.g. appointments)

/* Menu */
$menu-vertical-height: 70px;
$menu-vertical-padding: 15px;

@mixin menu-icon {
  max-width: 20px;
  max-height: 20px;
  margin-right: 8px;
  transform: translateY(2px);
}

/* Borders */
$border-radius-small: 3px;
$border-radius-large: 10px;
$border-width-thin: 1px;
$border-width-thick: 5px;

/* Layout */
@mixin container {
  width: calc(100% - #{$container-horizontal-padding} * 2);
  max-width: $container-width-max;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
}

@mixin row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: start;
  margin-bottom: 10px;
  @include sm-screen {
    display: block;
  }
  
  > * {
    flex: 1;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: $margin-between;
      @include sm-screen {
        margin-right: 0;
      }
    }
  }
}

/* Inputs */
@mixin input-wrap {
  display: flex;
  flex-direction: column;
  margin-top: $margin-top-medium;
}

@mixin input {
  margin-top: $margin-top-small;
  width: 100%;
  border-width: $border-width-thin;
  border-style: solid;
  border-color: $gray-mid;
  padding: 10px;
  font-size: 16px;
  border-radius: $border-radius-small;
  &:hover {
    border-color: $purple-secondary;
  }
}

/* Media query mixins - go to http://localhost:3000/dev/mediaqueries for reference */
@mixin lg-screen {
  @media only screen {
    @content;
  }
}
@mixin md-screen {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}
@mixin sm-screen {
  @media only screen and (max-width: 740px) {
    @content;
  }
}
@mixin xs-screen {
  @media only screen and (max-width: 460px) {
    @content;
  }
}
