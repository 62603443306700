@import "../../../styles/variables";

.line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  color: $purple-primary !important;

  &-img {
    width: 65px;
    height: 65px;
    border-radius: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e5e5e5;
    position: absolute;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    width: 100%;
    padding-left: 75px;

  }

  &-content-row {

    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
  }

  &-title {
    color: #4E5665;
    font-size: 15px;
    font-weight: 400;
  }

  &-price {
    line-height: 23px;
    float: right;
    font-weight: bold;
    font-size: 15px;
    margin-right: 80px;
    color: $purple-primary !important;
  }

  &-quantity-container {
    border: 1px solid #767676;
    float: left;
    border-radius: 3px;
  }

  &-quantity-update {
    color: #767676;
    display: block;
    float: left;
    height: 21px;
    line-height: 16px;
    font-family: monospace;
    width: 25px;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
  }

  &-quantity-update-form {
    display: inline;
  }

  &-quantity {
    color: black;
    width: 38px;
    height: 21px;
    line-height: 23px;
    font-size: 15px;
    border: none;
    text-align: center;
    -moz-appearance: textfield;
    background: transparent;
    border-left: 1px solid #767676;
    border-right: 1px solid #767676;
    display: block;
    float: left;
    padding: 0;
    border-radius: 0;
  }

  &-remove {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: 0;
    font-size: 20px;
    top: -4px;
    opacity: 0.5;
  }

  &-remove:hover {
    opacity: 1;
    cursor: pointer;
  }

  &-loading {
    opacity: 0.3;
    cursor: wait;
  }
}